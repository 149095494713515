














































import Currencies from 'piramis-base-components/src/components/Shop/Currencies'
import { InputSetups } from '@/mixins/input-setups'
import { successNotification } from '@/includes/services/NotificationService'
import CashRegisterToken from '@/components/HelpMessages/CashRegisterToken.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      Currencies,
      CashRegisterToken
    }
  }
})
export default class CreateNewCashRegister extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() isOpen!: boolean

  @Prop({ type:String, required: false }) initialName!:string

  cashRegister = this.cashRegisterField

  updated():void {
    this.cashRegister.name = this.initialName ?? ''
  }

  get cashRegisterField():{ name: string, token: string, currency: string, type: 'Telegram' } {
    return {
      name: '',
      token: '',
      currency: '',
      type: 'Telegram',
    }
  }

  onCancel(): void {
    this.isOpen = false
    this.cashRegister = this.cashRegisterField
  }

  createNewCashRegister(): void {
    this.$store.dispatch('createCashRegister', Object.assign({
      board_key: this.$store.state.boardsState.activeBoard!.board,
    }, this.cashRegister))
      .then(({ id }) => {
        successNotification(this.$t('success').toString())
        this.inputNewCashRegister(id)
      })
      .finally(() => this.isOpen = false)
  }

  @Emit('inputNewCashId')
  inputNewCashRegister(id: number): number {
    return id
  }
}
