var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","footer":null,"title":_vm.$t('choose_post_reaction_popup_title')},model:{value:(_vm.areReactionsOpen),callback:function ($$v) {_vm.areReactionsOpen=$$v},expression:"areReactionsOpen"}},[(_vm.reactionList.length)?_c('div',_vm._l((_vm.reactionList),function(r,index){return _c('div',{key:index,staticClass:"mb-2"},[_c('div',{staticClass:"flex"},[_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(r.text)+" ")]),_c('a-button',{staticClass:"ml-auto",attrs:{"color":"btn-success","icon":"check"},on:{"click":function (){_vm.reaction.reaction = r.guid; _vm.selectedReaction=r.text; _vm.areReactionsOpen = false}}})],1)])}),0):_c('div',[_vm._v(" "+_vm._s(_vm.$t('empty_post_reactions_message'))+" ")])]),_c('a-card',{staticClass:"my-2 reaction-item",attrs:{"size":"small","title":_vm.actionLabel(_vm.reaction.action.type)}},[_c('a-button',{attrs:{"slot":"extra","type":"danger","icon":"delete"},on:{"click":function($event){return _vm.$emit('remove')}},slot:"extra"}),_c('div',{staticClass:"flex justify-between items-center m-2"},[_c('span',{staticClass:"font-medium",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.selectedReaction)+" ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.areReactionsOpen=true}}},[_vm._v(" "+_vm._s(_vm.$t('choose_post_reaction_from_message'))+" ")])],1),_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.reaction,
          'key': 'lower_bound',
          'prefix': 'post_reaction_',
          'validation': 'required'
        }
      }}}),(_vm.reaction.action.type === _vm.PostActionType.ForwardMessageAction)?_c('div',[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.reaction.action,
            'key': 'mode',
            'prefix': 'forward_action_',
            'options': _vm.forwardMessageActionModeOptions,
            'clearable': false,
          }
        }}}),_c('channels-info-table',{attrs:{"targets":_vm.reaction.action.targets},on:{"update-targets":function($event){_vm.reaction.action.targets = $event}}})],1):_vm._e(),(_vm.reaction.action.type === _vm.PostActionType.NotifyUserAction)?_c('div',[_c('chips-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.reaction.action,
            'key': 'users',
            'prefix': 'post_',
          }
        }}}),_c('editor-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.reaction.action,
            'key': 'message',
          }
        }}})],1):_vm._e(),(_vm.reaction.action.type === _vm.PostActionType.NotifyUsersAction)?_c('div',[_c('users-info-table',{attrs:{"values":_vm.reaction.action.users},on:{"input":function($event){_vm.reaction.action.users = $event}}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args':{
            'model': _vm.reaction.action,
            'key':'message',
            'prefix': ((_vm.snakeCase(_vm.reaction.action.type)) + "_"),
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'validation': 'required',
            'availableButtonsTypes': _vm.pmButtons,
            'has-media': true,
            'base-api-url': 'api.presscode.app/cabinet',
            'targetToUpload': function () {
              return {
                'target': 'target',
                'target_id': _vm.$store.getters.getBoardById(_vm.$route.params.id).board
              }
            },
          }
        }}})],1):_vm._e(),([_vm.PostActionType.SendCommentAction, _vm.PostActionType.SendReplyCommentAction, _vm.PostActionType.SendReplyMessageAction, _vm.PostActionType.EditPostAction].includes(_vm.reaction.action.type))?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args':{
          'model': _vm.reaction.action.message,
          'key':'variants',
          'prefix': ((_vm.snakeCase(_vm.reaction.action.type)) + "_"),
          'buttonsHelpView': _vm.EditorButtonsHelpView,
          'validation': 'required',
          'availableButtonsTypes': _vm.postMessageButtons,
          'has-media': true,
          'base-api-url': 'api.presscode.app/cabinet',
          'targetToUpload': function () {
            return {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params.id).board
            }
          },
        }
      }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }