






































































































































































import { actionLabel } from '@/mixins/ActionLabels'

const VIEWS_EXTRA_GUID = '3f3f3503-a2fd-4d25-b087-8c7536d29778'
const FORWARDS_EXTRA_GUID = '9213ecc7-c02c-44f9-b872-a3bc07ac63cd'
const COMMENTS_EXTRA_GUID = '31fa8f24-959e-4713-8a9a-7050c52ae92a'

import { InputSetups } from '@/mixins/input-setups'
import { MessageVariants, PostActionType, PostReaction } from '@/includes/types/Post.types'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import ChannelsInfoTable from '@/components/ChannelsInfoTable.vue'
import UsersInfoTable from '@/components/UsersInfoTable.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { Mixins, Prop, VModel } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import { AvailableButtonsTypes } from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types";

@Component({
  components: { ChannelsInfoTable, UsersInfoTable },
  data() {
    return {
      actionLabel,
      EditorButtonsHelpView,
      snakeCase
    }
  }
})export default class ReactionItem extends Mixins(UseFields, InputSetups) {
  @VModel() reaction!: PostReaction & { key: string, label: string }

  @Prop({ required: true, type: String }) board !: string

  @Prop({ type: Number }) itemIndex !: number

  @Prop({ required: true }) variants !: Array<MessageVariants>

  @Prop() postMessageButtons!: AvailableButtonsTypes

  PostActionType: any = PostActionType

  selectedReaction = ''

  areReactionsOpen = false

  get reactionList(): Array<{ text: string, guid: string }> {
    const variantsCopy = [ ...this.variants ]
    const reactionList: Array<{ text: string, guid: string }> = [
      {
        guid: VIEWS_EXTRA_GUID,
        text: this.$t('reaction_extra_views') as string
      },
      {
        guid: FORWARDS_EXTRA_GUID,
        text: this.$t('reaction_extra_forwards') as string
      },
      {
        guid: COMMENTS_EXTRA_GUID,
        text: this.$t('reaction_extra_comments') as string
      },
    ]

    variantsCopy.forEach((variant: any) => {
      if (variant.buttons.length) {
        variant.buttons.forEach((buttons: any) => {
          buttons.forEach((buttonElem: any) => {
            reactionList.push({
              text: buttonElem.text,
              guid: buttonElem.guid
            })
          })
        })
      }
    })
    return reactionList
  }

  created() {
    if (this.reaction) {
      this.variants.forEach((variant: any) => {
        if (variant.buttons.length) {
          variant.buttons.forEach((buttons: any) => {
            buttons.forEach((buttonElem: any) => {
              if (buttonElem.guid === this.reaction.reaction) {
                this.selectedReaction = buttonElem.text
              }
            })
          })
        }
      })
    }
    if ([ VIEWS_EXTRA_GUID, FORWARDS_EXTRA_GUID, COMMENTS_EXTRA_GUID ].includes(this.reaction.reaction)) {
      this.selectedReaction = this.reactionList.find(v => v.guid === this.reaction.reaction)!.text
    }
  }

}
