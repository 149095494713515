



















































import TestStateMixin from '../mixins/TestStateMixin'
import TestStatusInfo from './TestStatusInfo.vue'
import { Component, Prop } from 'vue-property-decorator'
import { Target, TestBoardStateCode, TestChannelStateCode } from '@/includes/types/Board.types'
import { snakeCase } from 'lodash'

@Component({
  components: {
    TestStatusInfo,
  },
  data() {
    return {
      snakeCase,
    }
  }
})
export default class PostBotState extends TestStateMixin {
  @Prop() currentChannels!: Array<number> | ['ALL_TARGETS']

  get channelsInvalidTestState(): Array<{ title: string, username: string, value: number, state: TestChannelStateCode }> {
    return this.boardChannels
      .filter((channel) => {
        const inInvalid = [
          TestChannelStateCode.UndefinedError,
          TestChannelStateCode.ApiError,
          TestChannelStateCode.ChannelNotFoundError,
          TestChannelStateCode.ChannelPermissionSendMessageError,
        ]

        if (inInvalid.includes(channel.test_state)) {
          return channel
        }
      })
      .map((channel) => {
        return {
          title: channel.title,
          username: `@${ channel.username }`,
          value: channel.id,
          state: channel.test_state,
        }
      })
  }

  get boardTestState(): boolean {
    return [
      TestBoardStateCode.UndefinedError,
      TestBoardStateCode.HandlerError,
      TestBoardStateCode.ApiError,
      TestBoardStateCode.CommunicationError,
    ].includes(this.$store.state.boardsState.activeBoard!.test_state)
  }

  get boardChannels(): Array<Target> {
    const board = this.$store.getters.getBoardById(this.$route.params.id)

    if (board) {
      return board.targets.filter((target) => this.currentChannels[0] === 'ALL_TARGETS' ? target : (this.currentChannels as Array<number>).includes(target.id))
    }
    return []
  }
}
