



















































































import { InputSetups } from '../../mixins/input-setups'
import { RequestPost } from '@/includes/types/Post.types'
import { ChannelService } from '@/includes/services/ChannelService'
import { ChannelSlots } from '@/includes/types/Board.types'
import { errorNotification } from '@/includes/services/NotificationService'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { Component, Emit, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

@Component({
  computed: {
    ...mapGetters([ 'boardTargetOptions', 'getBoardById' ])
  }
})
export default class PostTargetSelect extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {

  @VModel() model!: RequestPost

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  @Emit('onSlotUpdate')
  updateSlot(slots: Array<ChannelSlots> | null): Array<ChannelSlots> | null {
    return slots
  }

  @Emit()
  targetsChange(values: Array<SelectOptionData>):Array<SelectOptionData> {
    return values
  }

  getBoardById!:GetBoardByIdType

  boardTargetOptions!:(boardId:string) => Array<SelectOptionData>

  activeKey = 1

  @Watch('activeKey')
  onActiveKeyChange(): void {
    this.model.origin = null
    this.model.targets = []
    this.updateSlot(null)
  }

  targetOptions: Array<SelectOptionData> = []

  optionsBackup: Array<SelectOptionData> = []

  get originOptions(): Array<SelectOptionData> {
    return this.boardTargetOptions(this.$route.params.id)
      .slice()
      .filter((o) => this.model.targets && !this.model.targets.includes(o.value))
  }

  handleOriginInput(optionValue: SelectOptionData | null): void {
    if (optionValue === null) {
      this.updateSlot(null)
      this.targetOptions = this.optionsBackup
    } else {
      this.getChannelSlots([ optionValue.value ])
      this.targetOptions = this.optionsBackup.filter(option => option.value !== optionValue.value)
    }
  }

  async getChannelSlots(selectedChannels: Array<number>): Promise<void> {
    try {
      const { items } = await ChannelService.getChannelSlots('tg', {
        board_key: this.getBoardById(this.$route.params.id)!.board,
        channel_ids: selectedChannels
      })

      if (items.length > 0) {
        this.updateSlot(items)
      } else {
        this.updateSlot(null)
      }
    } catch (e:any) {
      errorNotification(e)
      this.updateSlot(null)
    }
  }

  processChannelSlots(options:Array<SelectOptionData>):void {
    if (options.length) {
      if (options.find(o => o.value === 'ALL_TARGETS')) {
        const channels = this.optionsBackup.filter(option => option.value !== 'ALL_TARGETS')

        this.getChannelSlots(channels.map(v => v.value))
        this.$store.dispatch('updateHasSelectedPostTargetsLinkedChat', { targets: channels.map(v => v.value), boardId:this.$route.params.id })
      } else {
        this.getChannelSlots(options.map(v => v.value))
        this.$store.dispatch('updateHasSelectedPostTargetsLinkedChat', { targets: options.map(v => v.value), boardId:this.$route.params.id })
      }
    } else {
      this.getChannelSlots([])
      this.$store.dispatch('updateHasSelectedPostTargetsLinkedChat', { targets: [], boardId:this.$route.params.id })
    }
  }

  handlePostTargetsInput(values: Array<SelectOptionData>): void {
    this.processChannelSlots(values)
    this.targetsChange(values)

    if (this.model.targets && this.model.targets.includes('ALL_TARGETS')) {
      this.model.targets = this.model.targets.filter(option => option === 'ALL_TARGETS')
      this.targetOptions = this.optionsBackup.filter(option => option.value === 'ALL_TARGETS')
    } else {
      this.targetOptions = this.optionsBackup
    }
  }

  mounted(): void {
    const targets = this.boardTargetOptions(this.$route.params.id)
    let options:Array<SelectOptionData> = []

    if (targets.length === 1) {
      options = [ ...targets ]
    } else {
      options = [ {
        label: this.$t('select_all_targets_option', [ targets.length ]).toString(),
        value: 'ALL_TARGETS'
      }, ...targets ]
    }

    if (this.model.targets.length) {
      this.getChannelSlots(this.model.targets.filter(v => v !== 'ALL_TARGETS') as Array<number>)
      this.$store.dispatch('updateHasSelectedPostTargetsLinkedChat', { targets: this.model.targets, boardId:this.$route.params.id })
    }

    options.forEach(o => this.targetOptions.push(o))
    this.optionsBackup = [ ...this.targetOptions ]

    if (this.model.origin) {
      this.activeKey = 2
    }
  }
}
