var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.isOpen,"ok-text":_vm.$t('accept'),"cancel-text":_vm.$t('cancel')},on:{"ok":_vm.createNewCashRegister,"cancel":_vm.onCancel}},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.cashRegister,
        'key': 'name',
        'prefix': 'new_cash_register_',
      }
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.cashRegister,
        'key': 'token',
        'prefix': 'new_cash_register_',
        'customHelpMessage': _vm.CashRegisterToken
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.cashRegister,
        'key': 'currency',
        'options': _vm.Currencies,
        'clearable': false,
        'prefix': 'new_cash_register_',
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }