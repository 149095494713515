





































import PostActionTypePopup from '@/components/Post/PostActionTypePopup.vue'
import ReactionItem from '@/components/Post/Reactions/ReactionItem.vue'
import { IPostActionMessage, PostReaction } from '@/includes/types/Post.types'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, VModel, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { AvailableButtonsTypes } from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types";

@Component({
  components: {
    ReactionItem,
    PostActionTypePopup
  }
})
export default class ReactionsList extends Vue {
  @VModel() reactions !: Array<PostReaction>

  @Prop({ required: true, type: String }) board !: string

  @Prop({ required: true }) postMessage !: any

  @Prop({ type: Boolean, default: false }) disabled!:boolean

  @Prop() actionList!: Array<IPostActionMessage & { label: string, icon: string, disabled?:boolean }>

  @Prop({ type:Boolean, default: false }) validateActions!:boolean

  @Prop() postMessageButtons!: AvailableButtonsTypes

  reactionsCopy: Array<PostReaction> = []

  rerenderKey = 0

  areActionTypesOpen = false

  @Watch('reactionsCopy', { deep: true })
  onReactionCopyChange(value: Array<PostReaction> | undefined) {
    if (value) {
      this.reactions = cloneDeep(value).map((val: any) => {
        delete val.key
        delete val.label
        return val
      })
    }

    this.areActionTypesOpen = false
  }

  removeReaction(index: number): void {
    this.reactionsCopy.splice(index, 1)
    this.rerenderKey += 1
  }

  addReaction(action: IPostActionMessage): void {
    const actionCopy = cloneDeep(action)
    this.reactionsCopy.push({
      ...actionCopy,
      reaction: '',
      lower_bound: 1,
    })
  }

  mounted(): void {
    this.reactionsCopy = this.reactions ?? []
  }
}
