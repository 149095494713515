











































































import { InputSetups } from '@/mixins/input-setups'
import { Schedule } from '@/includes/types/Post.types'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import moment from 'moment'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'

@Component({
  data() {
    return {
      timezoneList,
      moment,
    }
  }
})
export default class PostTime extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() scheduleModel!: Schedule

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  currentTimezone = 'UTC'

  timezoneModalOpen = false

  get postDate():string {
    return this.scheduleModel.run_time.split(' ')[0] ?? ''
  }

  set postDate(newDate:string) {
    this.updateDatetime(newDate, undefined)
  }

  get postTime():string {
    return this.scheduleModel.run_time.split(' ')[1] ?? ''
  }

  set postTime(newTime:string) {
    this.updateDatetime(undefined, newTime)
  }

  updateDatetime(date = this.postDate, time = this.postTime):void {
    this.scheduleModel.run_time = `${ date } ${ time }`
  }

  handleTimezoneOkClick(): void {
    this.scheduleModel.timezone = this.currentTimezone
    this.timezoneModalOpen = false
  }

  mounted(): void {
    const postDate = this.$route.query?.post_date?.toString()
    const postTime = this.$route.query?.post_time?.toString()

    if (postDate && postTime) {
      this.postDate = postDate
      this.postTime = postTime
    } else if (this.scheduleModel.run_time) {
      this.postDate = this.scheduleModel.run_time.split(' ')[0]
      this.postTime = this.scheduleModel.run_time.split(' ')[1]
    } else {
      this.postTime = moment().add('1', 'h').startOf('h').format('HH:mm:ss')
      this.postDate = moment().add('1', 'd').format('YYYY-MM-DD')
    }
  }
}
