






























































































































































































































































































































































































































import PostTargetSelect from '@/components/Post/PostTargetSelect.vue'
import {
  Conditions,
  IPost,
  MessageVariants,
  PayedPostMessage,
  Poll,
  PostCommentsStateTypes,
  PostMessage,
  PostType,
  Quiz,
  RequestPost,
  RequestPostDraft,
  Schedule,
  ScheduleType,
  Style,
  WatermarkPosition,
  WatermarkType,
} from '@/includes/types/Post.types'
import { InputSetups } from '@/mixins/input-setups'
import PeriodSettings from '@/components/Post/Period/PeriodSettings.vue'
import PostStylesSetup from '@/components/Post/PostStylesSetup.vue'
import ActionList from '@/components/Post/Actions/ActionList.vue'
import ReactionsList from '@/components/Post/Reactions/ReactionsList.vue'
import Quizes from '@/components/Quizes.vue'
import { PostService } from '@/includes/services/PostService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import PostTime from '@/components/Post/PostTime.vue'
import BoardStatusBotDomainError from '@/components/TestStatusHelpInfo/Board/BoardStatusBotDomainError.vue'
import { unsavedMessage, unsavedMessageExists } from '@/includes/AccountDataHelper'
import { Board, ChannelSlots, Target } from '@/includes/types/Board.types'
import ActionsListMixin from '@/components/Post/Actions/ActionsListMixin'
import formatTime from '@/includes/formatTime'
import { canCreatePost, canPostNow } from '@/includes/PermissionHelper'
import SuggestPostActionButton from '@/components/SuggestPostActionButton.vue'
import TelegramPostPreview from '@/views/PostFeed/TelegramPostPreview.vue'
import DrawerWidthMixin from '@/includes/DrawerWidthMixin'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import { BoardTargetOptionsType, GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import { DI_HAS_CHANNELS_CONTENT_SUBSCRIPTION } from '@/includes/CONSTANTS'
import { LimitedSubscriptionType } from '@/includes/types/Channel.types'
import PostShopFieldsWrapper from '@/components/Post/PostShopFieldsWrapper.vue'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'
import validateActions from '@/components/Post/logic/ValidateActions'

import { AvailableButtonsTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { MessageEditorWithMediaTab } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import isMobile from 'piramis-js-utils/lib/isMobile'

import { Mixins, ProvideReactive, Watch } from 'vue-property-decorator'
import { cloneDeep, upperFirst } from 'lodash'
import Component from 'vue-class-component'
import moment from 'moment'
import { Guid } from 'guid-typescript'
import { mapGetters } from 'vuex'

import PostBotState from '@/components/PostBotState.vue'
import Button
  from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons";
import LimitedSubscriptionLinkButton from "@/components/ProjectButtons/LimitedSubscriptionLinkButton";

const ALL_TARGETS = 'ALL_TARGETS'

Component.registerHooks([
  'beforeRouteLeave'
])

@Component({
  components: {
    PostShopFieldsWrapper,
    EditorButtonsHelpView,
    TelegramPostPreview,
    SuggestPostActionButton,
    BoardStatusBotDomainError,
    PostTargetSelect,
    Quizes,
    ReactionsList,
    PeriodSettings,
    ConfigField,
    Accordion,
    PostStylesSetup,
    ActionList,
    Tags,
    PostTime,
    HelpMessage,
    PostBotState,
  },
  data() {
    return {
      MessageEditorWithMediaTab,
      ScheduleType,
      formatTime,
      canCreatePost,
      EditorButtonsHelpView,
      canPostNow,
    }
  },
  computed: {
    ...mapGetters([ 'boardTargetOptions', 'getBoardById' ])
  }
})
export default class Post extends Mixins<InputSetups, UseFields, ActionsListMixin, DrawerWidthMixin>(InputSetups, UseFields, ActionsListMixin, DrawerWidthMixin) {
  hasChannelsContentSubscription: boolean = false

  getBoardById!: GetBoardByIdType

  boardTargetOptions!: BoardTargetOptionsType

  existedStyle = false

  postType: PostType | null = null

  isPostLoaded = false

  scheduleType = '' as ScheduleType

  hasPostActions = false

  hasPostReactions = false

  initConditions: Array<Conditions> = []

  slots: Array<ChannelSlots & { guid?: string }> | null = null

  selectSlotModalOpen = false

  isCreateButtonDisabled = true

  periodDisabled = false

  leaveRouteModalIsOpen = false

  modelPost(): any {
    if (this.$route.query.type !== PostType.Paid.toLowerCase()) {
      return {
        message: {} as PostMessage | Poll | Quiz,
        delayed_actions: [],
        reaction_actions: [],
      }
    } else {
      return {
        delayed_actions: [],
        reaction_actions: [],
        message: {
          type: PostType.Paid,
          variants: [],
          product_id: null,
          cash_register_id: null
        } as PayedPostMessage
      }
    }
  }

  model: RequestPost = {
    post: {
      ...this.modelPost(),
    },
    schedule: {
      period: {
        conditions: [],
        unit: 'MINUTES',
        interval: 0
      } || null,
      timezone: '',
      run_time: ''
    },
    targets: [],
    origin: null,
    style: {
      compress_level: null,
      protect_content: false,
      disable_notify: false,
      disable_link_preview: false,
      pin: false,
      data: {
        color: null,
        post_price: 0,
        post_comments_state: PostCommentsStateTypes.None
      },
      watermark_type: WatermarkType.None,
      watermark_position: WatermarkPosition.Center,
      watermark_data: null,
      prefix_message: [ {
        attachments: [],
        text: '',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
      } ],
      postfix_message: [ {
        attachments: [],
        text: '',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
      } ],
      actions: [],
    },
    tags: [],
  }

  isLoading = false

  runtimeType: 'Slot' | 'Custom' = 'Custom'

  slotPublishTo: ChannelSlots & { guid?: string } | null = null

  // debHandler = debounce(this.modelFieldsChangeHandler, 1500)

  styleBackup = {} as Style

  postFeedPreviewVisible = false

  watchPreview = true

  postButtons: any = null

  @Watch('model.post.message.variants')
  onMediaEditorChange(value: Array<MessageVariants>) {
    if (value) {
      this.isCreateButtonDisabled = true
      value.forEach((m: MessageVariants) => {
        if (!m.text && m.attachments && !m.attachments.length || m.text === '\n') {
          this.isCreateButtonDisabled = true
        } else if (m.attachments && (m.text || m.attachments.length)) {
          this.isCreateButtonDisabled = false
        } else {
          this.isCreateButtonDisabled = false
        }
      })
    }
  }

  get previewButtonIcon(): 'arrow-left' | 'arrow-right' | '' {
    if (isMobile() || this.windowWidth <= 991) {
      return ''
    }

    if (this.watchPreview) {
      return 'arrow-left'
    } else {
      return 'arrow-right'
    }
  }

  get firstFiveSlots(): Array<ChannelSlots & { guid?: string }> | null {
    if (this.slots) {
      if (this.slots.length > 5) {
        return this.slots.slice(0, 5)
      }
      return this.slots
    }
    return []
  }

  get allSlots(): Array<ChannelSlots & { guid?: string }> {
    if (this.slots) {
      return this.slots.slice(5)
    }
    return []
  }

  get baseValidation(): boolean {
    return !this.model.targets!.length || this.periodDisabled
  }

  get mainFieldsQuizValidation(): boolean {
    if (this.model.post.message.type === 'Quiz' || this.model.post.message.type === 'Poll') {
      return !this.model.post.message.questions.length ||
        !this.model.post.message.text ||
        this.model.post.message.open_period > 600
    }
    return true
  }

  get dropdownScheduleTypes(): Array<{ key: string, icon: string, action: ScheduleType }> {
    return [
      {
        'key': 'post_publish_test',
        'icon': 'tool',
        'action': ScheduleType.Test
      },
      {
        'key': 'post_save_draft',
        'icon': 'folder',
        'action': ScheduleType.Draft,
      }
    ]
  }

  get postPageActionType(): 'new' | 'copy' | 'edit' | 'edit-published' | 'show-suggest' | 'edit-suggest' {
    return this.$route.params.actionType as 'new' | 'copy' | 'edit' | 'edit-published' | 'show-suggest' | 'edit-suggest'
  }

  get hasPostStyle(): boolean {
    return !!this.$route.query?.styleId?.toString() || !!this.model.style?.id
  }

  get styleAccordionTitle(): string {
    if (this.model.style && this.model.style.id) {
      return this.$t('watch_selected_style_slider', [ this.styleNameById(this.model.style.id) ]).toString()
    }
    return this.$t('watch_style_settings_empty').toString()
  }

  get postCardTranslation(): string {
    if ([ 'new', 'copy' ].includes(this.postPageActionType) && !this.$route.query.suggest) {
      return this.$t(`field_create_new_${ this.postType!.toLowerCase() }_title`).toString()
    }
    if (this.postPageActionType === 'edit-published') {
      return this.$t(`field_edit_published_post_title`).toString()
    }
    if (this.postPageActionType === 'show-suggest') {
      return this.$t('field_show_suggested_post_title').toString()
    }
    if (this.postPageActionType === 'edit-suggest') {
      return this.$t('field_edit_suggested_post_title').toString()
    }
    if (this.$route.query.suggest) {
      return this.$t('field_suggest_new_post_title').toString()
    }
    return this.$t(`field_edit_${ this.postType!.toLowerCase() }_title`).toString()
  }

  get resultTargets(): Array<number | 'ALL_TARGETS'> {
    if (this.model.targets.includes('ALL_TARGETS')) {
      return this.boardTargetOptions(this.$route.params.id).map(value => value.value)
    }
    return this.model.targets
  }

  get initializeVariants(): Array<MessageVariants> {
    return [ {
      attachments: [],
      text: '',
      buttons: [],
      remove_previous: false,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
    } ]
  }

  get hasFirstMessageBaseFields(): boolean {

    const targetsExist = this.model.targets.length > 0

    if (this.model.post.message.type === PostType.Post) {
      const firsMessage = this.model.post.message.variants[0]

      return firsMessage.attachments!.length > 0 || (firsMessage.text !== '\n' && firsMessage.text.length > 0) || targetsExist
    }
    if (this.model.post.message.type === PostType.Quiz || this.model.post.message.type === PostType.Poll) {
      return this.model.post.message.text.length > 0 || this.model.post.message.questions.length > 0 || targetsExist
    }

    return false
  }

  get channelsToPost(): Array<SelectOptionData> {
    if (this.model.targets.includes('ALL_TARGETS')) {
      return this.boardTargetOptions(this.$route.params.id)
    }
    return this.boardTargetOptions(this.$route.params.id).filter(value => this.model.targets.includes(value.value))
  }

  get postMessageButtons(): AvailableButtonsTypes {
    if (this.postType === PostType.Paid) {
      return this.pmButtons
    }

    return this.getDefaultButtons({
      moreProButtons: [
        new LimitedSubscriptionLinkButton({
          i18n: this.$i18n,
          getArticlesFn: this.getArticlesSelectOptions,
          getHasChannelsContentSubscription: () => this.hasChannelsContentSubscription
        })
      ]
    })
  }

  goToSlotsSettings(): void {
    this.$router.push({
      name: 'Publish_settings',
      params: {
        id: this.$route.params.id,
        channelId: this.model.targets[0].toString()
      },
      hash: '#slots'
    })
  }

  activeSlotButtonClasses(slot: ChannelSlots & { guid?: string }): Record<string, boolean> {
    return {
      'slot-card_active-slot': this.runtimeType === 'Slot' && slot.guid === this.slotPublishTo!.guid
    }
  }

  handleEditSuggestedPostButton(): void {
    PostService.deleteSuggestPost('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      post_key: this.$route.query.postId.toString()
    })
      .then(() => {
        this.suggestPost()
      })
      .catch(errorNotification)
  }

  handlePreviewClick(): void {
    if (isMobile() || this.windowWidth <= 991) {
      this.postFeedPreviewVisible = true
    } else {
      this.watchPreview = !this.watchPreview
    }
  }

  onSlotClick(slot: ChannelSlots & { guid?: string }): void {
    this.runtimeType = 'Slot'

    this.slotPublishTo = slot
    this.model.schedule.run_time = slot.date + ' ' + slot.slot.time

    this.selectSlotModalOpen = false
  }

  styleNameById(styleId: number | undefined): string {
    if (this.$store.state.boardsState.activeBoard?.config.styles !== null) {
      for (const [ name, style ] of Object.entries(this.$store.state.boardsState.activeBoard?.config.styles)) {
        if ((style as Style).id === Number(styleId)) {
          return name
        }
      }
      return ''
    }
    return ''
  }

  initializePostMessage(): void {
    const postQuestionaire = {
      type: this.postType,
      text: '',
      open_period: 0,
      is_anonymous: true,
      questions: [],
    }
    if (this.postType === PostType.Poll) {
      this.model.post.message = { ...postQuestionaire, allows_multiple_answers: true } as Poll
    }
    if (this.postType === PostType.Quiz) {
      this.model.post.message = { ...postQuestionaire, correct_option_id: '', explanation: '' } as Quiz
    }
    if (this.postType && [ PostType.Post, PostType.Paid ].includes(this.postType)) {
      this.$set(this.model.post.message, 'variants', this.initializeVariants)
      this.model.post.message.type = this.postType
    }
  }

  hasAdSlot(slots: Array<ChannelSlots & { guid?: string }>): ChannelSlots & { guid?: string } | undefined {
    return slots.find(s => s.slot.ads_only)
  }

  regularSlot(slots: Array<ChannelSlots & { guid?: string }>): ChannelSlots & { guid?: string } | undefined {
    return slots.find(s => !s.slot.ads_only)
  }

  handleTargetsChange(targets: Array<SelectOptionData>): void {
    const targetIds = targets.map(t => t.value)
    const board: Board = this.getBoardById(this.$route.params.id)!

    const boardTargets = board.targets.filter(target => targetIds.includes(target.id))

    this.hasChannelsContentSubscription = boardTargets.every((t: Omit<Target, 'config'>) => t.limited_subscription_type === LimitedSubscriptionType.Content)
  }

  setSlotDateTime(slots: Array<ChannelSlots & { guid?: string }> | null): void {
    if (slots) {
      this.slots = slots.map(slot => {
        this.$set(slot, 'guid', Guid.create().toString())
        return slot
      })
    }

    if (slots === null) {
      this.slotPublishTo = null
      this.runtimeType = 'Custom'
      this.slots = []
    } else {
      if (this.postPageActionType === 'new') {
        this.runtimeType = 'Slot'

        const adSlot = this.hasAdSlot(slots)
        const regularSlot = this.regularSlot(slots)

        if (this.styleBackup) {
          if (this.styleBackup.data === undefined || this.styleBackup.data === null) {
            if (slots[0].slot.ads_only) {
              this.slotPublishTo = regularSlot ?? null
            } else {
              this.slotPublishTo = slots[0]
            }
          }

          if (this.styleBackup.data) {
            if (this.styleBackup.data.post_price > 0 && !slots[0].slot.ads_only) {
              if (adSlot) {
                this.slotPublishTo = adSlot
              } else {
                this.slotPublishTo = regularSlot ?? null
              }
            }

            if (this.styleBackup.data.post_price > 0 && slots[0].slot.ads_only) {
              this.slotPublishTo = slots[0]
            }

            if (this.styleBackup.data.post_price <= 0 && slots[0].slot.ads_only) {
              this.slotPublishTo = regularSlot ?? null
            }

            if (this.styleBackup.data.post_price <= 0 && !slots[0].slot.ads_only) {
              this.slotPublishTo = slots[0]
            }
          }
        }

        if (this.slotPublishTo !== null) {
          this.model.schedule.run_time = this.slotPublishTo?.date + ' ' + this.slotPublishTo?.slot.time
        } else {
          this.slotPublishTo = null
          this.runtimeType = 'Custom'
        }
      }
    }
  }

  suggestPost(): void {
    this.isLoading = true
    this.preparePostData()
      .then(data => this.validatePost(data))
      .then(validatedPost => {
        PostService.suggestPost('tg', validatedPost)
          .then((post) => {
            successNotification()

            this.$store.commit('AdminTemplate/SET_FIELD_TO_ACCOUNT_DATA', { unsavedPostData: null })
            this.$store.dispatch('AdminTemplate/saveAccountData')

            this.gotoPlannerPage({
              calendar: CalendarPostsSource.Suggested,
              date: moment(post.post.schedule.run_time).format('YYYY-MM-DD')
            })
          })
          .catch((e) => {
            errorNotification(e)
            this.gotoPlannerPage()
          })
          .finally(() => {
            this.isLoading = false
          })
      })
      .catch(errorNotification)
  }

  handleDropdownItem(action: ScheduleType): void {
    this.scheduleType = action

    if (action === ScheduleType.Suggest) {
      this.suggestPost()
    }
    if (action === ScheduleType.PostNow) {
      this.createPost()
    }
    if (action === ScheduleType.Test) {
      this.testPost()
    }
    if (action === ScheduleType.Draft) {
      this.savePost()
    }
  }

  testPost(): void {
    PostService.testPost('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      post: this.getPost().message,
      style: this.model.style
    })
      .then(() => {
        successNotification()
      })
      .catch(errorNotification)
      .finally(() => this.scheduleType = '' as ScheduleType)

  }

  savePost(): Promise<void> {
    return new Promise(resolve => {
      this.isLoading = true
      this.preparePostData()
        .then((validatedPost) => {
          this.$store.dispatch('createDraft', validatedPost)
            .then(() => {
              this.isNotSave = false
              successNotification()
            })
            .finally(() => {
              this.isLoading = false
              this.gotoPlannerPage()
            })
        })
        .catch(errorNotification)

      resolve()
    })
  }

  gotoPlannerPage(query?: Record<string, string>): void {
    this.$router.push({
      name: 'posts_planner',
      params: {
        id: this.$route.params.id,
      },
      query: { ...query }
    })
  }

  editPost() {
    PostService.deletePost('tg', {
      'board_key': this.getBoardById(this.$route.params.id)!.board,
      'post_key': this.$route.query.postId
    })
      .then(this.createPost)
      .catch(errorNotification)
  }

  validatePost(postData: RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>): Promise<RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>> {
    return new Promise((resolve, reject) => {
      if (this.slotPublishTo !== null && this.runtimeType === 'Slot') {
        const isSlotAd = this.slotPublishTo.slot.ads_only

        if (this.styleBackup && this.styleBackup.data) {
          const postPrice = this.styleBackup.data.post_price

          if (isSlotAd && postPrice <= 0) {
            reject(this.$t('ads_only_post'))
          }

          if (!isSlotAd && postPrice > 0) {
            reject(this.$t('regular_only_posts'))
          }
        }
        if (this.styleBackup.data === undefined || this.styleBackup.data === null) {
          if (isSlotAd) {
            reject(this.$t('ads_only_post'))
          }
        }
      }

      const post = postData as RequestPost

      if (post.post.message.type === PostType.Poll || post.post.message.type === PostType.Quiz) {
        if (post.post.message.questions.length < 2) {
          reject(this.$t('poll_questions_min_values'))
        }
      }

      if (post.post.message.type === PostType.Paid) {
        if (post.post.message.product_id === null || post.post.message.cash_register_id === null) {
          reject(this.$t('paid_post_fill_shop_fields'))
        }
      }

      if (post.post.message.type === PostType.Post || post.post.message.type === PostType.Quiz || post.post.message.type === PostType.Poll) {
        if (post.post.delayed_actions && post.post.delayed_actions.length) {
          const result = validateActions(post.post.delayed_actions)

          if (result) resolve(postData)
          reject(this.$t('actions_are_not_valid_error'))
        }

        if (post.post.reaction_actions && post.post.reaction_actions.length) {
          const result = validateActions(post.post.reaction_actions)

          if (result) resolve(postData)
          reject(this.$t('reactions_are_not_valid_error'))
        }
      }

      resolve(postData)
    })
  }

  createPost(): void {
    this.isLoading = true
    this.preparePostData()
      .then(this.validatePost)
      .then(validatedPost => {
        PostService.createPost('tg', validatedPost)
          .then((post) => {
            successNotification()

            this.$store.commit('AdminTemplate/SET_FIELD_TO_ACCOUNT_DATA', { unsavedPostData: null })
            this.$store.dispatch('AdminTemplate/saveAccountData')

            this.isNotSave = false

            this.gotoPlannerPage({
              calendar: CalendarPostsSource.Schedule,
              date: moment(post.post.schedule.run_time).format('YYYY-MM-DD')
            })
          })
          .catch((e) => {
            errorNotification(e)
            this.gotoPlannerPage()
          })
          .finally(() => {
            this.isLoading = false
          })
      })
      .catch(errorNotification)
      .finally(() => this.isLoading = false)
  }

  preparePostData(): Promise<RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>> {
    return new Promise(resolve => {
      let postData: RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>

      if (this.scheduleType === ScheduleType.Draft) {
        postData = {
          board_key: this.getBoardById(this.$route.params.id)!.board,
          message: { ...this.getPost().message }
        }
      } else if (this.scheduleType === ScheduleType.PostNow || this.scheduleType === ScheduleType.Test) {
        postData = {
          board_key: this.getBoardById(this.$route.params.id)!.board,
          targets: this.resultTargets,
          post: this.getPost(),
          style: this.model.style,
          origin:this.model.origin,
        }
      } else if (this.postType === PostType.Paid) {
        postData = {
          board_key: this.getBoardById(this.$route.params.id)!.board,
          targets: this.resultTargets,
          schedule: this.getSchedule(),
          post: this.getPost(),
          style: this.model.style,
          origin: this.model.origin,
        }
      } else {
        postData = {
          board_key: this.getBoardById(this.$route.params.id)!.board,
          targets: this.resultTargets,
          schedule: this.getSchedule(),
          post: this.getPost(),
          style: this.model.style,
          origin: this.model.origin,
        }
      }

      resolve(postData)
    })
  }

  getSchedule(): Schedule {
    return {
      run_time: this.model.schedule.run_time,
      period: this.model.schedule!.period!.interval ? {
        interval: Number(this.model.schedule!.period!.interval),
        unit: this.model.schedule!.period!.unit,
        conditions: this.getConditions()
      } : null,
      timezone: this.model.schedule.timezone
    }
  }

  getConditions(): Array<Conditions> | null {
    return this.model!.schedule!.period!.conditions ? this.model!.schedule!.period!.conditions : null
  }

  getPost(): IPost {
    let resPostObject = {
      delayed_actions: this.model.post.delayed_actions,
      reaction_actions: this.model.post.reaction_actions,
      message: {
        type: this.model.post.message.type,
      } as any,
    }

    if (this.model.post.message.type === 'Post') {
      resPostObject.message = { ...resPostObject.message, variants: this.getVariants() }
    } else if (this.model.post.message.type === PostType.Paid) {
      resPostObject.message = {
        ...resPostObject.message,
        variants: this.getVariants(),
        cash_register_id: this.model.post.message.cash_register_id,
        product_id: this.model.post.message.product_id
      } as PayedPostMessage
    } else {
      resPostObject.message = { ...resPostObject.message, ...this.model.post.message }
      resPostObject.message.open_period = resPostObject.message.open_period === 0 ? null : resPostObject.message.open_period
    }

    return resPostObject as IPost
  }

  getVariants(): Array<MessageVariants> {
    if (this.model.post.message.type === PostType.Post || this.model.post.message.type === PostType.Paid) {
      return this.model.post.message.variants
    } else {
      return []
    }
  }

  isButtonDisabled(scheduleType?: ScheduleType): boolean {
    if (scheduleType === ScheduleType.Draft || scheduleType === ScheduleType.Test) {
      if (this.model.post.message.type === 'Poll') {
        return this.mainFieldsQuizValidation
      }
      if (this.model.post.message.type === 'Quiz') {
        return this.mainFieldsQuizValidation || !this.model.post.message.correct_option_id
      }
      return this.isCreateButtonDisabled
    } else {
      if (this.model.post.message.type === 'Poll') {
        return this.baseValidation || this.mainFieldsQuizValidation
      }
      if (this.model.post.message.type === 'Quiz') {
        return this.baseValidation || this.mainFieldsQuizValidation! || !this.model.post.message.correct_option_id
      }
      return this.baseValidation || this.isCreateButtonDisabled
    }
  }

  handleSaveButtonClick(): void {
    this.scheduleType = ScheduleType.Schedule

    if ([ 'new', 'copy' ].includes(this.postPageActionType)) {
      this.createPost()
    } else if (this.postPageActionType === 'edit-published') {
      this.editPublishedPost()
    } else {
      this.editPost()
    }
  }

  editPublishedPost(): void {
    this.isLoading = true
    const postBody = {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      post_key: this.$route.query.postId.toString(),
      message: (this.model.post.message as PostMessage).variants,
      style: this.model.style
    }

    PostService.editPublishedPost('tg', postBody)
      .then(() => successNotification())
      .catch(errorNotification)
      .finally(() => {
        this.isLoading = false
        this.gotoPlannerPage()
      })
  }

  setStyleToPost(styleId: string): void {
    this.model.style = this.$store.state.boardsState.activeBoard?.config.styles[styleId]
    this.styleBackup = cloneDeep(this.$store.state.boardsState.activeBoard?.config.styles[styleId])
  }

  setPostType(type: PostType): void {
    switch (type) {
      case 'Quiz':
        this.postType = PostType.Quiz
        break
      case 'Poll':
        this.postType = PostType.Poll
        break
      case 'Post':
        this.postType = PostType.Post
        break
      case 'Paid':
        this.postType = PostType.Paid
        break
      default:
        throw new Error(`Unknown post type: ${ type }`)
    }
  }

  findPostAndSetModel():void {
    const draft = this.$store.state.draftsState.drafts!.find((p: any) => p.key.token_id === Number(this.$route.query.draft))
    this.setPostType(draft.message.type)
    this.model.post.message = { ...draft.message }

    this.isLoading = false
    this.isPostLoaded = true
  }

  getSavedPosts(): void {
    if (!this.$store.state.draftsState.drafts) {
      this.$store.dispatch('requestDrafts')
        .then(this.findPostAndSetModel)
    } else {
      this.findPostAndSetModel()
    }
  }

  isNotPaid(post: IPost): post is IPost {
    return post.message.type !== PostType.Paid
  }

  getPostById(): void {
    PostService.getOriginalPost('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      post_key: this.$route.query.postId.toString()
    })
      .then((data) => {
        const originalPost = data.post

        this.model.schedule.run_time = originalPost.schedule.run_time

        // set post type
        this.setPostType(originalPost.message.message.type as PostType)

        // set post message variants
        this.model.post.message = { ...originalPost.message.message }

        if (this.isNotPaid(originalPost.message) && this.isNotPaid(this.model.post)) {
          // set post actions if exists
          if (originalPost.message.delayed_actions && originalPost.message.delayed_actions.length) {
            this.model.post.delayed_actions = originalPost.message.delayed_actions
            this.hasPostActions = true
          }

          // set post reactions if exists
          if (originalPost.message.reaction_actions && originalPost.message.reaction_actions.length) {
            this.model.post.reaction_actions = originalPost.message.reaction_actions
            this.hasPostReactions = true
          }
        } else {
          this.requestShopData()
        }

        // set post timezone and period if exists
        this.model.schedule!.timezone = originalPost.schedule.timezone
        if (originalPost.schedule.period) {
          this.model.schedule!.period = originalPost.schedule.period
        }

        // set post targets and origin groups if exists
        this.model.targets = originalPost.target.targets
        if (originalPost.target.original) {
          this.model.origin = originalPost.target.original
        }

        this.model.style = originalPost.style
        this.styleBackup = cloneDeep(originalPost.style)

        // if period || price are set - open extra settings accordion
        if (originalPost.schedule.period !== null) {
          if (originalPost.schedule.period && originalPost.schedule.period.conditions) {
            this.initConditions = originalPost.schedule.period.conditions
          }
        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.isPostLoaded = true
        this.$store.dispatch('updateHasSelectedPostTargetsLinkedChat', {
          targets: this.model.targets,
          boardId: this.$route.params.id
        })
        this.isLoading = false
      })
  }

  loadBackupPost(): void {
    this.model = cloneDeep(unsavedMessage(this.$route.params.id)!.post)
    this.isPostLoaded = true
  }

  initializeSuggestedPost(): void {
    if (this.postPageActionType === 'show-suggest') {
      this.disableFields = true
    }

    PostService.getSuggestPost('tg', {
      post_key: this.$route.query.postId.toString(),
      board_key: this.getBoardById(this.$route.params.id)!.board,
    })
      .then(({ data }) => {
        this.setPostType(data.post.message.type)
        this.model = data as RequestPost
      })
      .catch(errorNotification)
      .finally(() => {
        this.isPostLoaded = true
      })
  }

  disableFields = false

  async requestShopData(): Promise<void> {
    if (!this.$store.state.shopState.products) {
      await this.$store.dispatch('getProducts', { board_key: this.$store.state.boardsState.activeBoard!.board })
    }
    if (!this.$store.state.shopState.cashRegisters) {
      await this.$store.dispatch('getCashRegisters', { board_key: this.$store.state.boardsState.activeBoard!.board })
    }
  }

  mounted(): void {
    this.$store.commit('updateValidateCommentButtonHasGroup', true)

    if (this.$route.query.type) this.postType = this.model.post.message.type = upperFirst(this.$route.query.type.toString()) as PostType
    if (this.$route.query.channel) this.model.targets.push(Number.parseInt(this.$route.query.channel.toString()))

    if (this.model.schedule) this.model.schedule.timezone = this.getBoardById(this.$route.params.id)!.timezone
    if (this.getBoardById(this.$route.params.id)!.targets.length === 1) this.model.targets = [ this.getBoardById(this.$route.params.id)!.targets[0].id ]

    const styleId = this.$route.query.styleId?.toString()

    if (styleId) {
      this.model.style = cloneDeep(this.$store.state.boardsState.activeBoard?.config.styles[styleId])
      this.styleBackup = cloneDeep(this.$store.state.boardsState.activeBoard?.config.styles[styleId])
    }

    if (this.$route.query.draft) {
      this.isLoading = true
      this.getSavedPosts()
    } else if ([ 'edit', 'edit-published', 'copy' ].includes(this.postPageActionType) && this.$route.query.postId) {
      this.isLoading = true
      this.getPostById()
    } else if (this.$route.query.restore && unsavedMessageExists(this.$route.params.id)) {
      this.loadBackupPost()
    } else if ([ 'show-suggest', 'edit-suggest' ].includes(this.postPageActionType) && this.$route.query.postId) {
      this.initializeSuggestedPost()
    } else {
      this.initializePostMessage()
      if (this.postType === PostType.Paid) {
        this.requestShopData()
      }
      this.isPostLoaded = true
    }
  }

  isNotSave = true

  beforeRouteLeave(flow, to, next): void {
    if (!this.isButtonDisabled(ScheduleType.Test) && this.isNotSave && ![ 'edit', 'edit-suggest', 'edit-published' ].includes(this.postPageActionType)) {
      this.leaveRouteModalIsOpen = true

      this.leaveRouteAndSave = () => {
        this.scheduleType = ScheduleType.Draft
        this.savePost()
          .then(() => {
            next()
          })
      }

      this.leaveRouteNotSave = () => next()
    } else {
      next()
    }
  }

  leaveRouteNotSave = () => {}

  leaveRouteAndSave = () => {}
}
