var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":"80%","title":"Сделать перевод"},on:{"ok":_vm.createNewProduct,"cancel":_vm.onCancel},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"grid grid-cols md:grid-cols-2 gap-2"},[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.product,
          'key': 'name',
          'prefix': 'new_product_',
        }
      }}}),_c('multi-currency-input',{attrs:{"setup":{
        'func': _vm.setPrice,
        'args': {
          'model': this,
          'key': 'product',
          'options': _vm.CurrencyList,
          'prefix': 'price_'
        }
      }}})],1),_c('textarea-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.product,
        'key': 'description',
        'prefix': 'new_product_',
      }
    }}}),_c('file-uploader-targets-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.product,
        'key': 'image',
        'base-api-url': 'api.presscode.app/cabinet',
        'available-mime-types': ['image/png', 'image/jpeg'],
        'targetToUpload': function () {
          return {
            'target': 'target',
            'target_id': _vm.$store.state.boardsState.activeBoard.board
          }
        },
        'prefix': 'new_product_',
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }