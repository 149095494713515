






































































import { InputSetups } from '../../mixins/input-setups'
import { PayedPostMessage, PostType, RequestPost } from '@/includes/types/Post.types'
import CreateNewCashRegister from '@/components/shop/components/CreateNewCashRegister.vue'
import CreateNewProductModal from '@/components/shop/components/CreateNewProductModal.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { warningNotificationWrapper } from "piramis-base-components/src/logic/helpers/NotificationService";
import { ProductState } from "piramis-base-components/src/components/Shop/Products/types";
import CreateIfEmpty from 'piramis-base-components/src/components/CreateIfEmpty.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    CreateIfEmpty,
    CreateNewCashRegister,
    CreateNewProductModal
  }
})
export default class PostShopFieldsWrapper extends Mixins<UseFields, InputSetups>(UseFields, InputSetups){

  @VModel() model!:RequestPost

  newCashRegisterName = ''

  newProductName = ''

  createNewCashRegisterIsOpen = false

  createNewProductIsOpen = false

  handleCashRegisterOption(option?:Pick<SelectOption, 'label'>):void {
    if (option) {
      this.newCashRegisterName = option.label
    }

    this.createNewCashRegisterIsOpen = true
  }

  handleProductOption(option?:Pick<SelectOption, 'label'>):void {
    if (option) {
      this.newProductName = option.label
    }

    this.createNewProductIsOpen = true
  }

  gotoShop() {
    this.$router.push({
      name: 'Shop'
    })
  }

  get products(): Array<{ label: string, value: any }> {
    if (this.model.post.message.type === PostType.Paid && this.$store.state.shopState.cashRegisters !== null && this.$store.state.shopState.products !== null) {
      const cashRegisterId = this.model.post.message.cash_register_id
      const cashRegister = this.$store.state.shopState.cashRegisters!.find((cashRegister) => cashRegister.id === cashRegisterId)

      if (cashRegister) {
        return this.$store.state.shopState.products!
          .filter((product) => product.currency === cashRegister.currency && product.state === ProductState.Active)
          .map((product) => {
            return {
              label: product.name,
              value: product.id
            }
          })
      }

      return []
    }

    return []
  }

  inputCashRegister(id: number): void {
    (this.model.post.message as PayedPostMessage).cash_register_id = id
  }

  inputNewProduct({ id, currency }: { id: number, currency: string }): void {
    const cash = this.$store.state.shopState.cashRegisters!.find((cash) => cash.id === (this.model.post.message as PayedPostMessage).cash_register_id)

    if (cash) {
      if (cash.currency === currency) {
        (this.model.post.message as PayedPostMessage).product_id = id
      } else {
        warningNotificationWrapper(this.$t('warning').toString(), this.$t('inserted_product_currency_is_not_valid').toString())
      }
    }
  }
}
