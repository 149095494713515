var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-shop-fields-wrapper"},[_c('create-new-cash-register',{attrs:{"initial-name":_vm.newCashRegisterName},on:{"inputNewCashId":_vm.inputCashRegister},model:{value:(_vm.createNewCashRegisterIsOpen),callback:function ($$v) {_vm.createNewCashRegisterIsOpen=$$v},expression:"createNewCashRegisterIsOpen"}}),_c('create-new-product-modal',{attrs:{"initial-name":_vm.newProductName},on:{"inputNewProduct":_vm.inputNewProduct},model:{value:(_vm.createNewProductIsOpen),callback:function ($$v) {_vm.createNewProductIsOpen=$$v},expression:"createNewProductIsOpen"}}),_c('create-if-empty',{attrs:{"options":_vm.$store.getters.getActiveCashRegisterOptions,"empty-message":_vm.$t('pi_cash_register_create_empty_message')},on:{"onCreateClick":_vm.handleCashRegisterOption}},[(_vm.$store.state.shopState.cashRegisters !== null)?_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model.post.message,
          'key': 'cash_register_id',
          'options': _vm.$store.getters.getActiveCashRegisterOptions,
          'taggable': true,
          'clearable': false,
          'createNewIcon': 'plus',
          'notFoundOption': _vm.$t('cash_register_not_found').toString(),
          'validation': 'required',
          'createButton': {
            title: _vm.$t('create_cash_register').toString(),
            callback: _vm.handleCashRegisterOption
          }
        }
      }}}):_vm._e()],1),_c('create-if-empty',{attrs:{"options":_vm.model.post.message.cash_register_id ? _vm.products : null,"empty-message":_vm.$t('pi_product_create_empty_message')},on:{"onCreateClick":_vm.handleProductOption}},[(_vm.$store.state.shopState.products !== null)?_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model.post.message,
          'key': 'product_id',
          'options': _vm.products,
          'taggable': true,
          'clearable': false,
          'createNewIcon': 'plus',
          'validation': 'required',
          'notFoundOptionText': _vm.$t('product_not_found').toString(),
          'disabled': !_vm.model.post.message.cash_register_id,
          'createButton': {
            title: _vm.$t('create_product').toString(),
            callback: _vm.handleProductOption
          }
        }
      }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }