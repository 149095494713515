














































































import CurrencyList from 'piramis-base-components/src/components/Shop/Currencies'
import { InputSetups } from '@/mixins/input-setups'
import { ProductType } from 'piramis-base-components/src/components/Shop/Products/types'
import { successNotification } from '@/includes/services/NotificationService'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import CurrencyInput from 'piramis-base-components/src/components/CurrensyInput/components/input.vue'
import MultiCurrencyInput from 'piramis-base-components/src/components/CurrensyInput/MultiCurrencyInput.vue'

import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      CurrencyList,
    }
  },
  components: {
    CurrencyInput,
    MultiCurrencyInput,
  }
})
export default class CreateNewProductModal extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() isOpen!: boolean

  @Prop({ type:String, required: false }) initialName!:string

  product = this.productFields

  updated():void {
    this.product.name = this.initialName ?? ''
  }

  get productFields():{ name: string, description: string, image: string, cost: number, currency: string } {
    return {
      name: this.initialName ?? '',
      description: '',
      image: '',
      cost: 0,
      currency: 'RUB',
    }
  }

  setPrice(arg: FieldData): FieldData {
    arg.setter = ({ cost, currency }) => {
      this.product.cost = cost
      this.product.currency = currency
    }
    return arg
  }

  onCancel():void {
    this.isOpen = false
    this.product = this.productFields
  }

  createNewProduct(): void {
    this.$store.dispatch('createProduct', Object.assign({
      type: ProductType.Service,
    }, this.product))
      .then(({ id, currency }) => {
        successNotification(this.$t('success').toString())
        this.inputNewProduct({ id, currency })
      })
      .finally(() => this.isOpen = false)
  }

  @Emit('inputNewProduct')
  inputNewProduct(args: { id: number, currency: string } ): { id: number, currency: string } {
    return args
  }
}
